var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "border-bottom pb-3 mb-3"
  }, [_c('FormGroup', {
    attrs: {
      "id": "description",
      "label": "Description",
      "error": _vm.validationErrors.description,
      "description": "A friendly description of the automation"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Category",
      "type": "select",
      "options": _vm.categoryOptions,
      "error": _vm.validationErrors.category,
      "description": "What category does this automation apply to? For example, if data comes in via a company-wide email receiver then this would be 'Company'. If the automation fetches data for a single meter, then this would be 'Account'."
    },
    model: {
      value: _vm.form.category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "source",
      "label": "Source",
      "type": "select",
      "options": _vm.sourceOptions,
      "error": _vm.validationErrors.source,
      "description": "What source does this data come from?"
    },
    model: {
      value: _vm.form.source,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "service",
      "label": "Service",
      "type": "select",
      "options": _vm.serviceOptions,
      "error": _vm.validationErrors.service,
      "placeholder": "Select a service..."
    },
    model: {
      value: _vm.form.service,
      callback: function ($$v) {
        _vm.$set(_vm.form, "service", $$v);
      },
      expression: "form.service"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "frequency",
      "label": "Frequency",
      "type": "select",
      "options": _vm.frequencyOptions,
      "error": _vm.validationErrors.frequency,
      "description": "How often this task is scheduled to run. 'Email Receiver' will run the task when an email is received."
    },
    model: {
      value: _vm.form.frequency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "frequency", $$v);
      },
      expression: "form.frequency"
    }
  }), _vm.form.category == 'account' ? _c('FormGroup', {
    attrs: {
      "id": "accountIds",
      "label": "Accounts",
      "type": "select-array-list",
      "options": _vm.accountOptions,
      "error": _vm.validationErrors.accountId,
      "placeholder": "Select an account...",
      "description": "Accounts to apply this automation to"
    },
    model: {
      value: _vm.form.accountIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountIds", $$v);
      },
      expression: "form.accountIds"
    }
  }) : _vm._e(), _vm.form.category == 'asset' ? _c('FormGroup', {
    attrs: {
      "id": "assetId",
      "label": "Assets",
      "type": "select-array-list",
      "options": _vm.assetOptions,
      "error": _vm.validationErrors.assetId,
      "placeholder": "Select an asset...",
      "description": "Assets to apply this automation to"
    },
    model: {
      value: _vm.form.assetId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetId", $$v);
      },
      expression: "form.assetId"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "active",
      "label": "Active",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    model: {
      value: _vm.form.active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }